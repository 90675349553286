import React from "react";
import ReactDOM from "react-dom";

export const About = () => {
	return (
		<>
			<h1>Hello</h1>
		</>
	);
};
